.image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 300px;
   
  
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}


h1 {
  text-align: center;
  font-size: 50px;
  
}

h2 {
  text-align: center;
}

@keyframes rotate{

  100%{
    transform:rotate(360deg);
  }
}